import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Explore from "../components/explore"

const HowtoPage = () => (
  <div id="indexPage">
    <Header/>

    <div className="content-pad">
      <p>
        التقديم على الشبكــــة المتوسطيـــة لمصـــــائد الأسمــــــــاك (FISH MED NET)
        تم تصميم وبناء مشروع الشبكــــة المتوسطيـــة لمصـــــائد الأسمــــــــاك (FISH MED NET) بهدف خلق بيئة إيجابية من التعاون بين مجتمعات الصيادين في جميع أنحاء البحر الأبيض المتوسط. بموجب هذه المبادرة ، إن الشبكــــة المتوسطيـــة لمصـــــائد الأسمــــــــاك (FISH MED NET) متاحة لأي مؤسسة متناهية الصغر إلى صغيرة ومتوسطة الحجم مسجلة، ترغب في المشاركة في هذا المشروع للاستفادة من خلال إدراجها وعرضها على المنصة الإلكترونية التي  توفر مشاهدتها والاتصال المباشر مع الجمهور على المستويين الوطني والدولي.
        هناك قاعدة بسيطة للمشاركة في هذه المبادرة وهي الالتزام بالإرشادات الطوعية الموضوعة في هذا المشروع. تهدف هذه الإرشادات إلى احترام البيئة والوضع الاجتماعي والاقتصادي  للمشاركين المحلين. هي في اتفاقية تحالف الأعمال (هي اتفاقية غير ملزمة ولا تتطلب دفع اي رسوم للمشاركة) والتي سيتم تسليمها بمجرد استعداد المؤسسة للمشاركة في هذه المبادرة.
        من خلال الالتزام بهذه القواعد الطوعية واحترامها ، تهدف الشبكــــة المتوسطيـــة لمصـــــائد الأسمــــــــاك (FISH MED NET) إلى خلق عالم أفضل!
        كيفية التقديم؟
        من أجل إبداء اهتمامك ، يرجى :
        الاتصال بالمكتب الاستشاري الخاص بالمشروع   حتى يمكن النظر في اهتمامك
        سوف نتصل بك بعد ذلك ونطلب منك إثباتًا على كونك شركة متناهية الصغر إلى صغيرة ومتوسطة الحجم مسجلة في بلدك.
        بمجرد التحقق من جميع المستندات ووالتاكد من انتظامها ، سنزودك بما يلي:
        أ -  نموذج للتعبئة  سيجمّع المعلومات الأساسية المتعلقة بنشاط اعمالك فقط ، و 
        ب - اتفاقية تحالف الأعمال التي تطلب الاستعداد الطوعي للمؤسسة لاحترام القواعد المذكورة أعلاه والالتزام بها. يجب التوقيع عليها وإعادتها إلى المكتب .
        عند استلام المكتب المختص لجميع الوثائق ، ستتم إضافة المؤسسة بعد فترة وجيزة.
      </p>

      <h2>مكاتب الاستشارة لدينا</h2>
      <table>
        <tr>
          <th>Country</th><th>Region</th><th>Address</th><th>Working hours</th><th>Email</th>
        </tr>
        <tr>
          <td>France</td><td>Corsica</td><td>-</td><td>-</td><td><a href="mailto:FMNCorsicaFR@gmail.com">FMNCorsicaFR@gmail.com</a></td>
        </tr>
        <tr>
          <td>Italy</td><td>Liguria</td><td>-</td><td>-</td><td><a href="mailto:fmnliguria@gmail.com">fmnliguria@gmail.com</a></td>
        </tr>
        <tr>
          <td>Italy</td><td>Puglia</td><td>-</td><td>-</td><td><a href="mailto:fmnpugliait@gmail.com">fmnpugliait@gmail.com</a></td>
        </tr>
        <tr>
          <td>Italy</td><td>Sardegna</td><td>-</td><td>-</td><td><a href="mailto:fmnsardegnait@gmail.com">fmnsardegnait@gmail.com</a></td>
        </tr>
        <tr>
          <td>Tunisia</td><td></td><td>-</td><td>-</td><td><a href="mailto:FMNTunisieTN@gmail.com">FMNTunisieTN@gmail.com</a></td>
        </tr>
        <tr>
          <td>Lebanon</td><td></td><td>-</td><td>-</td><td><a href="mailto:FMNLebanonlb@gmail.com">FMNLebanonlb@gmail.com</a></td>
        </tr>
        <tr>
          <td>Palestine</td><td></td><td>-</td><td>-</td><td><a href="mailto:fmngazapal@gmail.com">fmngazapal@gmail.com</a></td>
        </tr>
      </table>

      <h2>للإطلاع على آخر مستجداتنا</h2>
    </div>
    <Explore slug="index" />

    <Footer/>
  </div>
)

export default HowtoPage
